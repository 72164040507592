export const borderColor = '#5a6aa0d4'


export const colors = {
  border: borderColor
}

export const heights = {
  header: 0,
  footer: 30
}
